import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../../components/Layout";
import BlogRoll from "../../components/BlogRoll";

export default class BlogIndexPage extends React.Component {
    render() {
        return (
            <Layout>
                <Helmet>
                    <title>A Blog Not Just About Editing: Find Tutorials, Reviews And More</title>
                    <meta
                        name="description"
                        content="We like writing. It’s our job! We share our years of experience in the publishing industry, academia and film making: reviews, comment pieces and tutorials."
                    />
                </Helmet>
                {/* <div
					className="full-width-image-container margin-top-0"
					style={{
						backgroundImage: `url('/img/blog-index.jpg')`,
					}}
				>
					<h2
						className="has-text-weight-bold is-size-1"
						style={{
							boxShadow: "0.5rem 0 0 #ad3531, -0.5rem 0 0 #ad3531",
							backgroundColor: "#b70d31",
							color: "white",
							padding: "1rem",
						}}
					>
						Latest stories
					</h2>
				</div> */}
                <section className="section">
                    <div className="container">
                        <div className="content">
                            <BlogRoll />
                        </div>
                    </div>
                </section>
            </Layout>
        );
    }
}
